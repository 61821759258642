import * as React from "react"
import Layout from "../components/layout"
import {StaticImage} from "gatsby-plugin-image"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {
  cardTitle,
  bannerOverlay,
  bannerIdent
} from "../css/index.module.css"
import {
  projectCard,
  projectCardContainer,
  projectCardContent,
  projectCardLocation,
  projectDetail
} from "../css/projects.module.css"
import BrandedDivider from "../components/branded_divider"
import {projects} from "../data/projects"
import PageBanner from "../components/page_banner"
import {SEO} from "../components/seo"

const ProjectsPage = () => {
  return (
    <Layout pageTitle="Projects">
      <PageBanner title="An Extensive Portfolio">
        <StaticImage
          alt="EnginuityUSA - Projects"
          src="../images/projects.jpg"
          objectFit="cover"
        />
      </PageBanner>
      <Container className="my-5">
        <Row>
          {projects.map(section => {
            return (
              <Col lg={6} className="my-3">
                <div className={projectCard}>
                  <div className={projectCardContainer}>
                    <h4 className={cardTitle}><BrandedDivider/>{section.section_title}</h4>
                    <div className={projectCardContent}>
                      {section.projects.map((project, index) => {
                        return (
                          <div key={index} className={projectDetail}><div>{project.title}</div> <div className={projectCardLocation}>{project.location}</div></div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </Layout>
  )
}

export const Head = () => <SEO title={"Enginuty USA | Projects"} />

export default ProjectsPage
